:root {
  /* COLORS */

  --primary-color: rgba(0, 0, 0, 0);
  --secondary-color: rgba(0, 0, 0, 0);
  --light-primary-color: rgba(0, 0, 0, 0);
  --dark-primary-color: rgba(0, 0, 0, 0);
  --secondary-color: #35d8b4;
  --error-color: #d81f1f;
  --light-error-color: #ffe5dd;
  --dark-error-color: #7a0d0d;
  --warning-color: #d8c51f;
  --light-warning-color: #faffdd;
  --dark-warning-color: #7a6f0d;

  --background-gray-color: #f5f5f5;
  --gray-color-1: #eeeeee;
  --gray-color-2: #f9f9f9;
  --gray-color-3: #eaeaea;
  --gray-color-4: #d3d3d3;
  --gray-color-5: #9babc5;
  --gray-color-6: #6c6c6c;
  --gray-color-7: #939393;

  /* FONT SIZES */
  --text-xx-large: 40px;
  --text-x-large: 28px;
  --text-large: 20px;
  --text-regular: 16px;
  --text-small: 14px;
  --text-x-small: 12px;

  /* FONT WEIGHTS */
  --text-strong: 600;

  /* FONT FAMILY */
  --primary-font: 'Gilroy';

  /* SIZES */
  --size-1x: 4px;
  --size-2x: 8px;
  --size-3x: 12px;
  --size-4x: 16px;
  --size-5x: 24px;
  --size-6x: 32px;
  --size-7x: 48px;
  --size-8x: 64px;
  --size-9x: 96px;
  --size-10x: 128px;
  --size-11x: 192px;
  --size-12x: 256px;
  --size-13x: 384px;
  --size-14x: 512px;
  --size-15x: 640px;

  /* BORDER RADIUS */
  --border-radius-1x: 8px;
  --border-radius-2x: 13px;
  --border-radius-3x: 20px;

  /* GRAPH COLORS */
  --graph-color-1: #20d8ab;
  --graph-color-2: #2095d8;
  --graph-color-3: #7d21da;
  --graph-color-4: #2054d8;
  --graph-color-5: #b320d8;
  --graph-color-6: #d82036;
  --graph-color-7: #d84120;
  --graph-color-8: #d8a420;
  --graph-color-9: #d4d820;
  --graph-color-10: #9dd820;
  --graph-color-11: #20d875;
  --graph-color-12: #56fda3;
  --graph-color-13: #56e9fd;
  --graph-color-14: #5667fd;
  --graph-color-15: #a056fd;
  --graph-color-16: #fd56ba;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/public/fonts/Gilroy/Gilroy-Regular.ttf') format('truetype'),
    url('/public/fonts/Gilroy/Gilroy-Regular.woff') format('woff'),
    url('/public/fonts/Gilroy/Gilroy-Regular.woff2') format('woff2'),
    url('/public/fonts/Gilroy/Gilroy-Regular.eot') format('eot');
}

@font-face {
  font-family: 'Gilroy';
  src: url('/public/fonts/Gilroy/Gilroy-Semibold.ttf') format('truetype'),
    url('/public/fonts/Gilroy/Gilroy-Semibold.woff') format('woff'),
    url('/public/fonts/Gilroy/Gilroy-Semibold.woff2') format('woff2'),
    url('/public/fonts/Gilroy/Gilroy-Semibold.eot') format('eot');
  font-weight: 600;
}

body {
  margin: 0;
  font-family: var(--primary-font), 'Gilroy';
  background-color: var(--background-gray-color);
}

b {
  color: var(--highlight-color)
}

.bold {
  font-weight: var(--text-strong);
}

.logo {
  max-height: 90%;
}

.gray4Color {
  color: var(--gray-color-4);
}

.gray6Color {
  color: var(--gray-color-6);
}

.gray5Color {
  color: var(--gray-color-5);
}

.primaryColor {
  color: var(--primary-color);
}

.warningColor {
  color: var(--warning-color);
}

.mr2 {
  margin-right: var(--size-2x);
}

.mt2 {
  margin-top: var(--size-2x);
}

.mt4 {
  margin-top: var(--size-4x);
}

.mb4 {
  margin-bottom: var(--size-4x);
}

.mb8 {
  margin-bottom: var(--size-8x);
}

.ml4 {
  margin-left: var(--size-4x);
}

.textLarge {
  font-size: var(--text-large);
}

.textSmall {
  font-size: var(--text-small);
}

.textSmallSection {
  padding-top: 10px;
  font-size: var(--text-small);
}

.textStrong {
  font-weight: var(--text-strong);
}

.width100 {
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

ul.noBullet {
  list-style-type: none;
}

.desktopGrid {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'header header'
    'navbar main';
}

.mobileGrid {
  display: grid;
  grid-template-columns: 100vw;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'header'
    'main';
}

.container {
  display: grid;
  grid-template-columns: 300px 1fr;
  padding-bottom: var(--size-4x);
}

@media screen and (min-width: 780px) {
  .header {
    grid-area: header;
    position: sticky;
    top: 0;
    background-color: white;
    height: var(--size-7x);
    padding: var(--size-3x);
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
  }

  .navbar {
    grid-area: navbar;
    background-color: white;
    border-radius: var(--border-radius-3x);
    margin: var(--size-3x) 0 var(--size-3x) var(--size-2x);
    padding: var(--size-5x) var(--size-3x);
    height: 570px;
    min-width: 280px;
  }

  .collapsedNavbar {
    grid-area: navbar;
    background-color: white;
    border-radius: var(--border-radius-3x);
    margin: var(--size-3x) 0 var(--size-3x) var(--size-2x);
    padding: var(--size-3x);
    height: var(--size-5x);
    width: var(--size-5x);
  }
}

@media screen and (max-width: 780px) {
  .header {
    grid-area: header;
    position: sticky;
    top: 0;
    background-color: white;
    height: auto;
    padding: var(--size-3x);
    z-index: 1;
  }

  .headerBar {
    height: var(--size-7x);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .navbar {
    padding: var(--size-4x);
  }
}

.main {
  grid-area: main;
}

.navItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--size-2x);
  font-weight: var(--text-strong);
  color: var(--gray-color-5);
  padding: var(--size-1x) var(--size-2x) var(--size-1x) 0;
}

.navItem.selected {
  color: var(--primary-color);
  border-right: 3px solid var(--primary-color);
}

.cursorNotAllowed {
  cursor: not-allowed;
}

.navItem.disabled {
  pointer-events: none;
}

.highlight {
  color: var(--primary-color);
}

.navItemCheck {
  margin-left: -1px;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--gray-color-2);
  border: solid 1px var(--gray-color-3);
  min-width: var(--size-4x);
  min-height: var(--size-4x);
  max-width: var(--size-4x);
  max-height: var(--size-4x);
}

.navItemCheck.highlight {
  background-color: var(--light-primary-color);
  border: solid 1px var(--primary-color);
}

.navItemSeparator {
  border-left: solid 2px var(--gray-color-3);
  height: 20px;
  margin-left: var(--size-2x);
  display: flex;
  align-items: center;
  justify-content: center;
}

.navItemSeparator.highlight {
  border-left: solid 2px var(--primary-color);
}

.navDelimiter {
  height: 1px;
  background-color: var(--gray-color-3);
  width: 100%;
  margin-right: var(--size-4x);
  margin-left: var(--size-4x);
}

.formContainer {
  padding: var(--size-4x);
  border-radius: 5px;
  width: auto;
}

.floatingBox {
  border-radius: var(--border-radius-3x);
  padding: var(--size-4x);
}

.boxHeader {
  display: flex;
  justify-content: space-between;
  gap: var(--size-3x);
  align-items: flex-start;
}

.mobileMenuIcon {
  display: flex;
  border: solid 1px var(--gray-color-5);
  border-radius: var(--size-2x);
  padding: var(--size-1x) var(--size-2x) var(--size-1x) var(--size-1x);
  gap: var(--size-1x);
  align-items: center;
  width: fit-content;
  font-size: var(--text-x-small);
  color: var(--gray-color-6);
  margin-bottom: var(--size-2x);
  margin-left: var(--size-2x);
}

.success {
  color: var(--dark-primary-color);
  background-color: var(--light-primary-color);
  border: 1px solid var(--primary-color);
}

.error {
  color: var(--dark-error-color);
  background-color: var(--light-error-color);
  border: 1px solid var(--error-color);
}

.title {
  font-size: var(--text-x-large);
  padding: var(--size-4x) var(--size-4x) var(--size-2x) var(--size-4x);
}

.reportDates {
  padding: 0 var(--size-5x);
  color: var(--gray-color-6);
  margin-bottom: var(--size-6x);
}

@media screen and (max-width: 780px) {
  .title {
    font-size: var(--text-x-large);
    padding: 0 var(--size-3x);
  }

  .reportDates {
    padding: var(--size-1x) var(--size-3x);
    color: var(--gray-color-6);
    margin-bottom: var(--size-3x);
  }
}

.navbarHeader {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  color: var(--gray-color-5);
}

.navbarTitle {
  padding: 0 var(--size-3x) var(--size-2x) var(--size-2x);
  margin-bottom: var(--size-2x);
  font-size: var(--text-large);
}

.subtitle {
  font-size: var(--text-large);
  margin-bottom: var(--size-4x);
}

.icons {
  display: flex;
  column-gap: 20px;
}

.sectionBox {
  background-color: white;
  border-radius: var(--border-radius-3x);
  padding: var(--size-6x);
  margin-top: var(--size-4x);
}

.graphInformation {
  padding: var(--size-6x);
}

@media screen and (max-width: 780px) {
  .sectionBox {
    padding: var(--size-6x) var(--size-4x) var(--size-6x) var(--size-4x);
  }

  .formContainer {
    padding: var(--size-3x);
  }

  .graphInformation {
    padding: var(--size-3x);
  }
}

.buttonContainer {
  display: flex;
  gap: var(--size-4x);
  justify-content: center;
  padding: var(--size-4x);
}

.buttonContainerPdf {
  display: flex;
  gap: var(--size-4x);
  justify-content: center;
  padding: var(--size-2x) var(--size-4x) var(--size-4x) var(--size-4x);
  margin-left: auto;
  margin-right: 0;
}

button[type='submit'],
button[type='button'],
input[type='submit'] {
  background: var(--primary-color);
  color: white;
  border: none;
  font-size: var(--text-large);
  font-weight: var(--text-strong);
  cursor: pointer;
  padding: var(--size-3x);
  min-width: 100px;
  border-radius: var(--border-radius-3x);
  align-self: center;
  font-family: var(--primary-font), 'Gilroy';
}

button:disabled,
button[disabled] {
  background-color: var(--gray-color-1);
  color: var(--gray-color-6);
  cursor: no-drop
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

button[type='submit']:active,
input[type='button']:active,
input[type='submit']:active {
  transition: 0.3s all;
  transform: translateY(3px);
  border: 1px solid transparent;
  opacity: 0.8;
}

label {
  font-size: var(--text-regular);
  font-weight: var(--text-strong);
}

input,
select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: var(--border-radius-1x);
  border: 1px solid var(--gray-color-4);
  padding: var(--size-3x) 2rem var(--size-3x) var(--size-3x);
  font-size: var(--text-regular);
  font-family: var(--primary-font), 'Gilroy';
}

select {
  background-image: url(../public/icons/angle-down.svg);
  background-position: calc(100% - 10px) calc(1em + 1px), calc(100% - 10px) calc(1em + 1px), calc(100% - 0.5em) 0.5em;
  background-repeat: no-repeat;
}

input:focus,
select:focus {
  outline: none !important;
  border: 1px solid var(--primary-color);
}

.sectionTitle {
  margin-top: var(--size-4x);
  font-size: var(--text-large);
  font-weight: var(--text-strong);
}

.itemTitle {
  font-size: var(--text-regular);
  font-weight: var(--text-strong);
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  margin-bottom: var(--size-4x);
}

.itemCard {
  border: 1px solid var(--gray-color-4);
  border-radius: var(--border-radius-2x);
  padding: var(--size-4x);
  margin: var(--size-4x) var(--size-2x) var(--size-4x) var(--size-2x);
}

.itemLabel {
  font-size: var(--text-small);
  font-weight: var(--text-strong);
  color: var(--gray-color-7);
  margin-bottom: var(--size-2x);
}

.itemInputsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: var(--size-4x);
}

@media screen and (max-width: 1200px) {
  .itemInputsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(var(--size-11x), 1fr));
    grid-gap: var(--size-3x);
  }
}

.itemInput {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.inlineInputs {
  display: flex;
  justify-content: space-between;
  gap: var(--size-2x);
}

.addButton {
  font-size: var(--text-regular);
  cursor: pointer;
}

.addButton.disabled {
  cursor: not-allowed;
}

.emptyPlaceholder {
  margin: var(--size-4x);
  color: var(--gray-color-7);
}

.locationInput {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--size-3x);
}

.locationInput .label {
  width: 40%;
}

.locationInput .input {
  width: 60%;
}

@media screen and (max-width: 780px) {
  .locationInput {
    display: flex;
    flex-direction: column;
    gap: var(--size-2x);
    align-items: flex-start;
  }

  .locationInput .label {
    width: 100%;
  }

  .locationInput .input {
    width: 100%;
  }
}

.categoryHeader {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-top: var(--size-3x);
  margin-bottom: var(--size-1x);
  gap: var(--size-2x);
}

.categoryTitle {
  font-size: var(--text-regular);
  font-weight: var(--text-strong);
}

.categoryTotal {
  font-size: var(--text-large);
  font-weight: var(--text-strong);
  color: var(--primary-color);
  text-align: end;
}

.additionalSpendContainer {
  margin-top: var(--size-2x);
  margin-bottom: var(--size-7x);
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.additionalSpendInput {
  min-width: 300px;
  display: flex;
  align-items: center;
  gap: var(--size-2x);
  justify-content: space-between;
}

@media screen and (max-width: 780px) {
  .categoryTotal {
    font-size: var(--text-regular);
  }

  .additionalSpendContainer {
    flex-direction: column;
    align-items: stretch;
  }

  .additionalSpendInput {
    min-width: 0;
  }
}

.includedTransactions {
  margin-top: var(--size-1x);
  color: var(--primary-color);
  text-align: left;
  font-size: var(--text-regular);
}

.estimatedAmount {
  font-style: italic;
  text-align: left;
  font-size: var(--text-regular);
}

.additionalSpendLabel {
  text-align: left;
  color: var(--medium-gray-color);
  font-size: var(--text-regular);
  margin-bottom: 5px;
}

.noTransactions {
  margin-top: var(--size-2x);
  font-size: var(--text-regular);
}

.transactionItem {
  font-size: small;
  padding: 5px;
  display: flex;
  flex: 1 1 0px;
  justify-content: space-between;
  border-bottom: 1px solid var(--light-gray-color);
}

.mccContainer {
  max-height: 200px;
  overflow: scroll;
  border-radius: var(--border-radius-2x);
  background-color: var(--lighter-gray-color);
}

.mccItem {
  font-size: var(--text-regular);
  padding: var(--size-3x);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em;
  border-bottom: 1px solid var(--light-gray-color);
}

.name {
  flex-grow: 1;
  text-align: left;
}

.spend {
  flex-grow: 0;
  text-align: right;
  font-size: large;
  margin-left: var(--size-2x);
}

@media screen and (max-width: 780px) {
  .estimatedAmount {
    font-size: var(--text-small);
  }

  .mccItem {
    font-size: var(--text-small);
  }

  .spend {
    font-size: var(--text-small);
  }
}

.action {
  flex-grow: 0;
  margin-left: var(--size-2x);
}

.userDeleted {
  text-decoration: line-through;
  background-color: var(--light-error-color);
}

.resultTable {
  border-collapse: collapse;
  background-color: var(--gray-color-2);
  border-radius: var(--border-radius-2x);
  text-align: left;
  font-size: var(--text-regular);
  min-width: 600px;
}

th {
  padding: var(--size-3x) var(--size-3x) var(--size-3x) var(--size-3x);
  font-weight: var(--text-strong);
  border-bottom: 1px solid var(--gray-color-4);
}

td {
  padding: var(--size-2x) var(--size-3x) var(--size-2x) var(--size-3x);
}

.companyGrid {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(6, 1fr);
  grid-gap: 24px;
}

.loginGrid {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 24px;
}

.loginGrid .item1 {
  grid-area: 1 / 1 / 2 / 2;
}
.loginGrid .item2 {
  grid-area: 1 / 2 / 2 / 8;
}
.loginGrid .item3 {
  grid-area: 2 / 1 / 3 / 2;
}
.loginGrid .item4 {
  grid-area: 2 / 2 / 3 / 8;
}
.loginGrid .item5 {
  grid-area: 3 / 1 / 4 / 2;
}
.loginGrid .item6 {
  grid-area: 3 / 2 / 4 / 8;
}
.loginGrid .item7 {
  grid-area: 4 / 1 / 5 / 8;
}



.companyGrid .item1 {
  grid-area: 1 / 1 / 2 / 4;
}

.companyGrid .item2 {
  grid-area: 1 / 4 / 2 / 8;
}

.companyGrid .item3 {
  grid-area: 2 / 1 / 3 / 4;
}

.companyGrid .item4 {
  grid-area: 2 / 4 / 3 / 8;
}

.companyGrid .item5 {
  grid-area: 2 / 1 / 3 / 4;
}

.companyGrid .item6 {
  grid-area: 2 / 4 / 3 / 8;
}

.companyGrid .item7 {
  grid-area: 3 / 1 / 4 / 4;
}

.companyGrid .item8 {
  grid-area: 2 / 4 / 5 / 6;
}

.companyGrid .item9 {
  grid-area: 2 / 6 / 5 / 8;
}

.companyGrid .item10 {
  grid-area: 4 / 1 / 5 / 6;
}

.companyGrid .item11 {
  grid-area: 3 / 4 / 6 / 8;
}

.companyGrid .item12 {
  grid-area: 5 / 1 / 6 / 4;
}

.companyGrid .item13 {
  grid-area: 5 / 4 / 6 / 7;
}

.companyGrid .item14 {
  grid-area: 4 / 7 / 7 / 8;
}

.companyGrid .item15 {
  grid-area: 6 / 1 / 7 / 7;
}

.companyGrid .item16 {
  grid-area: 6 / 4 / 7 / 7;
}

.companyGrid .item17 {
  grid-area: 6 / 7 / 7 / 8;
}

@media screen and (max-width: 780px) {
  .companyGrid {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
}

.ghgpList {
  text-align: left;
}

.ghgpListItem::marker {
  color: var(--secondary-color);
  font-weight: 800;
  width: 1em;
  font-size: var(--text-x-large);
  vertical-align: middle;
}

.listItem::before {
  content: '\2022';
  color: var(--secondary-color);
  font-weight: 800;
  display: inline-block;
  width: 1em;
  font-size: var(--text-x-large);
  vertical-align: middle;
}

.subListItem {
  margin-left: var(--size-2x);
}

.infoBoxesContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--size-3x);
  align-items: stretch;
  margin-top: var(--size-4x);
  font-size: var(--text-regular);
}

@media screen and (max-width: 780px) {
  .infoBoxesContainer {
    grid-template-columns: 1fr;
  }
}

.infoBox {
  border-radius: var(--border-radius-3x);
  padding: var(--size-4x);
  color: var(--dark-primary-color);
  background-color: var(--light-primary-color);
  border: 1px solid var(--primary-color);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--size-1x);
}

.infoBoxDark {
  border-radius: var(--border-radius-3x);
  padding: var(--size-4x);
  color: var(--light-primary-color);
  background-color: var(--dark-primary-color);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--size-1x);
}

.grayBox {
  background-color: var(--gray-color-2);
  border-radius: var(--border-radius-2x);
  padding: var(--size-4x);
  margin-top: var(--size-3x);
  margin-bottom: var(--size-3x);
}

.totalEmissions {
  font-size: var(--text-xx-large);
  font-weight: var(--text-strong);
}

.emissionsOverview {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--size-6x);
}

@media screen and (max-width: 780px) {
  .emissionsOverview {
    display: flex;
    flex-direction: column;
    margin-top: 0;
  }

  .overviewChart {
    max-height: 300px;
  }
}

.checkboxFlex {
  display: flex;
  gap: var(--size-2x);
  margin: var(--size-3x) 0;
}

.checkboxContainer {
  margin-top: -0.1em;
  display: block;
  position: relative;
  width: var(--size-4x);
}

/* Hide the browser's default checkbox */
.checkboxContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  height: var(--size-4x);
  width: var(--size-4x);
  background-color: var(--gray-color-2);
  border: solid 1px var(--gray-color-6);
  border-radius: var(--border-radius-1x);
}

/* On mouse-over */
.checkboxContainer:hover input~.checkmark {
  background-color: var(--gray-color-4);
}

/* When the checkbox is checked */
.checkboxContainer input:checked~.checkmark {
  background-color: var(--light-primary-color);
  border: solid 1px var(--primary-color);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkboxContainer input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkboxContainer .checkmark:after {
  left: 5px;
  top: 2px;
  width: 4px;
  height: 9px;
  border: solid var(--primary-color);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.collapsibleHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal.fullScreen {
  background-color: white;
}

.modalContent {
  background-color: white;
  margin: var(--size-8x) auto;
  border-radius: var(--border-radius-2x);
  padding: var(--size-8x);
}

.modalContent.fullScreen {
  margin: 0;
}

.modalContent.confirmation {
  padding: var(--size-4x);
}

@media screen and (min-width: 780px) {
  .modalContent {
    width: 60%;
  }

  .modalContent.fullScreen {
    width: fit-content;
  }

  .modalContent.fullScreen.confirmation {
    width: auto;
  }
}

@media screen and (max-width: 780px) {
  .modalContent {
    margin: var(--size-4x);
    padding: var(--size-4x);
  }
}

.close {
  color: var(--gray-color-6);
  float: right;
  font-size: 28px;
  font-weight: highlight;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.link {
  font-weight: var(--text-strong);
  cursor: pointer;
  text-decoration: underline;
  color: black;
}

.tooltip {
  max-width: 300px !important;
}

.clickable {
  cursor: pointer;
}

.mapImage {
  max-width: 100%;
}

.loader {
  border: 16px solid var(--gray-color-3);
  border-top: 16px solid var(--gray-color-7);
  border-radius: 50%;
  animation: spin 2s linear infinite;
  position: absolute;
  width: 100px;
  height: 100px;
  left: 50%;
  top: 50%;
  margin-left: -50px;
  margin-top: -50px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.labelsContainer {
  display: flex;
  align-items: center;
  gap: var(--size-4x);
}

.chartInfo {
  text-align: flex-start;
  align-self: center;
}

@media screen and (max-width: 780px) {
  .ghgContainer {
    margin-top: 0;
    padding-top: var(--size-2x);
  }
}

.ghgTable {
  display: grid;
  grid-template-columns: 44% 28% 28%;
  grid-template-rows: repeat(15, auto);
  background-color: var(--gray-color-2);
  border: solid 1px var(--gray-color-4);
  border-radius: var(--border-radius-2x);
  text-align: left;
  font-size: var(--text-regular);
  padding-bottom: var(--size-4x);
  overflow: hidden;
}

.electricityHelperTable {
  display: grid;
  grid-template-columns: repeat(100%, auto);
  grid-template-rows: repeat(15, auto);
  background-color: var(--gray-color-2);
  border: solid 1px var(--gray-color-4);
  border-radius: var(--border-radius-2x);
  text-align: left;
  font-size: var(--text-regular);
  overflow: hidden;
  margin: var(--size-4x) var(--size-2x) var(--size-4x) var(--size-2x);
}

.electricityHelperTableHeader {
  font-weight: var(--text-strong);
  padding: var(--size-3x);
  background-color: var(--gray-color-1);
}

.electricityHelperTableRow {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  width: 100%;
}

.electricityHelperTableItem {
  margin: auto;
  width: 90%;
  padding: var(--size-3x);
}

.electricityHelperTableItemSlider {
  display: grid;
  grid-template-columns: 25% 75%;
  width: 80%;
  padding: var(--size-3x);
}

.electricityHelperTableItemSliderItemPercentage {
  margin: auto;
  width: 100%;
  padding: var(--size-2x);
}

.electricityHelperTableItemSliderItemElement {
  margin: auto;
  width: 100%;
  padding: var(--size-2x);
  padding-bottom: 25px;
}

.tableHeader {
  font-weight: var(--text-strong);
  padding: var(--size-3x);
  background-color: var(--gray-color-1);
}

.tableSubHeader {
  border-top: solid 1px var(--gray-color-4);
  font-weight: var(--text-strong);
  padding: var(--size-3x);
}

.tableItem {
  padding: var(--size-3x);
}

@media screen and (max-width: 780px) {
  .thirdColumn {
    display: none;
  }

  .ghgTable {
    grid-template-columns: 50% 50%;
  }
}

.benchmarkSection {
  display: grid;
  grid-template-columns: 40% 60%;
  grid-gap: var(--size-8x);
  padding: var(--size-8x);
}

@media screen and (max-width: 1200px) {
  .benchmarkSection {
    display: flex;
    flex-direction: column;
    gap: var(--size-3x);
    padding: var(--size-3x);
    align-items: stretch;
  }
}

.chartSection40 {
  display: grid;
  grid-template-columns: 40% 60%;
  grid-gap: var(--size-8x);
  padding: var(--size-8x);
}

.chartSection60 {
  display: grid;
  grid-template-columns: 60% 40%;
  grid-gap: var(--size-8x);
  padding: var(--size-8x);
}

.chartContainer {
  position: relative;
}

.chartContainer.mobile {
  display: none;
}

@media screen and (max-width: 780px) {

  .chartSection40,
  .chartSection60 {
    display: flex;
    flex-direction: column;
    gap: var(--size-3x);
    padding: var(--size-3x);
    align-items: stretch;
  }
}

.middleLabel {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
}

.resultTitle {
  font-size: var(--text-xx-large);
  margin: var(--size-3x) var(--size-4x) var(--size-3x) var(--size-4x);
}

@media screen and (min-width: 780px) {
  .resultTitleContainer {
    display: grid;
    grid-template-columns: auto auto;
  }
}

.resultSubTitle {
  font-size: var(--text-x-large);
  margin: var(--size-4x);
}

.resultSectionTitle {
  font-size: var(--text-large);
  margin: var(--size-7x) var(--size-7x) 0 var(--size-7x);
}

.divider {
  height: 1px;
  background-color: var(--gray-color-1);
  margin: 0 var(--size-8x) 0 var(--size-8x);
}

@media screen and (max-width: 780px) {
  .resultTitle {
    font-size: var(--text-x-large);
    margin: var(--size-6x) var(--size-3x) var(--size-2x) var(--size-3x);
  }

  .resultSubTitle {
    font-size: var(--text-x-large);
  }

  .scopeTotalEmissions {
    font-size: var(--text-regular);
    font-weight: 500;
  }

  .divider {
    margin: var(--size-3x);
  }

  .resultSectionTitle {
    font-size: var(--text-large);
    margin: var(--size-3x) var(--size-3x) 0 var(--size-3x);
  }
}

.primaryLink {
  color: var(--primary-color-dark);
  font-weight: var(--text-strong);
}

.resultText {
  margin: 0 var(--size-4x) var(--size-6x) var(--size-8x);
}

@media screen and (max-width: 780px) {
  .resultText {
    margin: 0;
    padding: var(--size-3x);
  }
}

.reviewTable {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 1fr;
  background-color: var(--gray-color-2);
  border: solid 1px var(--gray-color-4);
  border-radius: var(--border-radius-2x);
  text-align: left;
  font-size: var(--text-regular);
  padding-bottom: var(--size-4x);
  overflow: hidden;
}

.notSpecified {
  color: var(--warning-color);
  font-style: italic;
}

.footer {
  text-align: center;
  padding: 0 var(--size-4x) var(--size-4x) var(--size-4x);
}

.disclaimer {
  display: flex;
  align-items: baseline;
  text-align: left;
}

.noDataText {
  padding: var(--size-4x);
}

.institutionsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(var(--size-12x), 1fr));
  grid-gap: var(--size-3x);
}

.institutionHeader {
  display: flex;
  justify-content: space-between;
  gap: var(--size-3x);
  align-items: flex-start;
}

.institutionTitle {
  display: flex;
  justify-content: flex-start;
  gap: var(--size-3x);
  align-items: center;
}

.bankLogo {
  background-color: white;
  width: var(--size-8x);
  height: var(--size-8x);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.institutionBox {
  background-color: var(--gray-color-2);
  border-radius: var(--border-radius-2x);
  padding: var(--size-4x);
}

.reloadButtonContainer {
  display: flex;
  justify-content: flex-end;
}

.pgSectionBody {
  max-width: 900px;
}

.displayCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: var(--size-5x);
}

.displayEnd {
  display: flex;
  justify-content: flex-end;
}

.secondaryButton {
  border: 1px solid var(--gray-color-6);
  color: var(--gray-color-6);
  font-size: var(--text-large);
  font-weight: var(--text-strong);
  cursor: pointer;
  padding: var(--size-3x);
  border-radius: var(--border-radius-3x);
  align-self: center;
}

.destructiveButton {
  color: white;
  background-color: var(--error-color);
  font-size: var(--text-large);
  font-weight: var(--text-strong);
  cursor: pointer;
  padding: var(--size-3x);
  border-radius: var(--border-radius-3x);
  align-self: center;
}

.scopesContainer {
  position: relative;
  text-align: center;
}

.blurContent {
  filter: blur(15px);
  pointer-events: none;
  user-select: none;
}

.lockedTextBox {
  justify-content: center;
  padding: 10px;
  gap: 10px;
  display: flex;
  align-items: center;
}

.insightsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.categories {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 1cm
}

.recommendations {
  width: 100%;
  height: 100%;
  margin: 10px;
  padding: 10px;
  gap: 25px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: auto;
  border-radius: var(--border-radius-3x);
  border: 1px solid var(--gray-color-4);
  align-content: center;
}

.categoryBox {
  border-radius: var(--border-radius-3x);
  padding: var(--size-4x);
  color: var(--dark-primary-color);
  background-color: var(--light-primary-color);
  border: 1px solid var(--primary-color);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--size-1x);
  cursor: pointer;
}

.actionBox {
  border-radius: var(--border-radius-3x);
  padding: var(--size-4x);
  color: var(--dark-primary-color);
  background-color: var(--light-primary-color);
  border: 1px solid var(--primary-color);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--size-1x);
  cursor: pointer;
}

.recommendationBox {
  grid-column: span 3;
  border-radius: var(--border-radius-3x);
  padding: var(--size-4x);
  color: var(--dark-primary-color);
  background-color: var(--light-primary-color);
  border: 1px solid var(--primary-color);
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: var(--size-3x);
}

.recommendationWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.recommendationHeader {
  font-weight: bold;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.recommendationStats {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.recommendationContent {
  background-color: var(--gray-color-2);
}


.categoryBox.highlight {
  background-color: var(--light-primary-color);
  border: solid 3px var(--primary-color);
}

.actionBox.highlight {
  background-color: var(--light-primary-color);
  border: solid 3px var(--primary-color);
}

.customSlider {
  /* max width of your slider */
  max-width: 300px;
  width: auto;
  /* Optional: Only If you want to center your slider in the page */
  margin-right: 20px;
  margin-left: 20px;
}

.customSlider-track {
  /* Top value to align your track to the center of your thumb */
  top: 8px;
  /* thickness of the track */
  height: 4px;
 /* default color of your track */
  background: #ddd;
}

.customSlider-thumb {
  cursor: pointer;
  /*color for the thumb */
  background:  var(--primary-color);
  /* shape of the thumb: circle */
  width: 20px;
  height: 20px;
  border-radius: 100%;
  /* remove default outline when selected */
  outline: none;
}

.customSlider-track.customSlider-track-0 {
  /* color of the track before the thumb */
  background: var(--primary-color);
}